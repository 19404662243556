<template>
  <component :is="'b-card'">
    <!-- Header: Personal Info -->

    <!-- Form: Personal Info Form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-1">
        <b-row>
          <!-- Field: Ad -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group>
              <label for="sAdi">{{ $t('Ad') }}</label>
              <validation-provider #default="{ errors }" rules="required|max:50" name="Adı">
                <b-form-input id="sAdi" v-model="urunGrupData.sAdi" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Kullanıcı Tipi -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group>
              <label for="tur">{{ $t('Ürün Tür') }}</label>
              <validation-provider #default="{ errors }" rules="required" name="Ürün Tür">
                <v-select
                  v-model="urunGrupData.tur"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="urunGrupTurOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  :state="errors.length > 0 ? false:null"
                  input-id="tur" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group>
              <label for="sModul">{{ $t('Modül') }}</label>
              <validation-provider #default="{ errors }" rules="required" name="Modül">
                <v-select
                  v-model="urunGrupData.sModul"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="urunGrupModulOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  :state="errors.length > 0 ? false:null"
                  input-id="sModul" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
         
        <b-row class="mt-2">
          <b-col>
            <save-button 
            :onClickSave="saveUrunGrup.bind(false)"
            :showSaveAndClose="false"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'
// Alert
import AlertService from '@/common/alert.service'

// Options
import StaticOptions from '@/common/options/StaticOptions';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import SaveButton from '@/components/SaveButton.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SaveButton,
  },

  data() {
    return {
      required,
      min,
      max,
      urunGrupData: {
        sAdi: null,
        sModul: null,
        tur: null,
       
      },
      urunGrupTurOptions: StaticOptions.urunGrupTurOptions,
      urunGrupModulOptions: StaticOptions.urunGrupModulOptions,
    }
  },

  methods: {
    fetchUrunGrup() {
      if (this.$router.currentRoute.params.id !== -1) {
        this.$store.dispatch('fetchUrunGrup', { id: this.$router.currentRoute.params.id })
          .then(response => {
            if (response.statusCode === 200) {
              this.urunGrupData = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      } else {
        this.isCreate = true
      }
    },

    saveUrunGrup(isClose) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('saveUrunGrup', this.urunGrupData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
                if (isClose) {
                  this.$router.go(-1)
                }
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },
  },
  
  created() {
    this.fetchUrunGrup();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
